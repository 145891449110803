import React from 'react';

const Footer = () => {
    return (
        <div className="bg-black h-16 flex items-center justify-center px-6 text-sm sm:text-base">
            Need support?  U can ask or find more help:  <a href="https://linktr.ee/rakeshvillage" target="_blank">rakeshvillage</a>
        </div>
      );
}

export default Footer;