import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { darkTheme, getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Web3Provider } from "./context/Web3Context";

import logoIcon from "./assets/imgs/mark.jpg";
import { sepolia, sapphire, sapphireTestnet } from "viem/chains";

const customTheme = {
  ...darkTheme({
    borderRadius: "medium",
    overlayBlur: "large",
  }),
  fonts: {
    body: "VT323",
  },
  colors: {
    modalBackground:
      // "linear-gradient(137.58deg, #4B65F1 11.98%, rgba(250, 3, 255, 0.2) 185.22%)",
      "#111111",
    accentColor: "#333333",
    modalBorder: "#292929",
    modalText: "#eee",
  },
};

const config = getDefaultConfig({
  appName: "RakeshTokenLauncher",
  projectId: "c041808b9742e572cb7caae630bb9312",
  chains: [
    sapphire,
  ],
});

const queryClient = new QueryClient();

const WalletAvatar = (size) => {
  return (
    <img
      src={logoIcon}
      height={size}
      width={size}
      alt="eth-launchpad"
      style={{ borderRadius: 999 }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            appInfo={{
              appName: "eth-launchpad",
            }}
            modalSize="compact"          
            avatar={WalletAvatar}
          >
            <Web3Provider>
              <App />
            </Web3Provider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
