import React from 'react';
import { writeContract, getConnectors, waitForTransactionReceipt } from "@wagmi/core";
import { http, createConfig } from '@wagmi/core'
import { zkSync, zkSyncSepoliaTestnet } from '@wagmi/core/chains'

export const config = createConfig({
  chains: [zkSync],
  transports: {
    [zkSync.id]: http()
  }
})



export const Web3Context = React.createContext({

});

export const Web3Provider = ({ children }) => {

    const callContractWait = async (abi, address, method, params, accountAddress, callback, sendValue = null) => {
        // setTxStatus({ status: 'pending', payload: { uuid } })
        console.log(`_callContractWait: address >>> ${address}, method >>> ${method}, params >>> ${params}, sendValue >>> ${sendValue}`)
        let writeContractRequest = null;
        const connectors = getConnectors(config);
        console.log('connectors >>> ', connectors);
        const connector = connectors[0];
        try {
            if (sendValue) {
                writeContractRequest = {
                    address: address,
                    abi: abi,
                    functionName: method,
                    args: params,
                    account: accountAddress,
                    value: sendValue,
                    connector
                }
            } else {
                writeContractRequest = {
                    address: address,
                    abi: abi,
                    functionName: method,
                    args: params,
                    account: accountAddress,
                    connector
                }
            }
            writeContract(
                config,
                writeContractRequest
            ).then((hash) => {
                console.log('hash >>> ', hash);
                console.log("submitted..");
                // setTxStatus({ status: 'submitted', payload: { uuid, hash } });

                waitForTransactionReceipt(config, {
                    hash: hash
                }).then((data) => {
                    console.log('data >>> ', data);
                    console.log("confirmed");
                    // setTxStatus({ status: 'confirmed', payload: { uuid, hash: data.transactionHash } });
                    callback(null, data.transactionHash);
                }).catch((e) => {
                    if (e.message) {
                        console.log("rejected");
                        // setTxStatus({ status: 'rejected', payload: { uuid, error: e.message } })
                        return callback(e.message);
                    }
                    console.log("rejected");
                    // setTxStatus({ status: 'rejected', payload: { uuid, error: "Error estimating gas" } });
                    callback(e);
                });
            })
                .catch((error) => {
                    console.error(error);
                    if (!error.toString().includes("-32601")) {
                        if (error.message) {
                            console.log("rejected");
                            // setTxStatus({ status: 'rejected', payload: { uuid, error: error.message } });
                            return callback(error.message);
                        }
                        console.log("rejected");
                        // setTxStatus({ status: 'rejected', payload: { uuid, error: error } });
                        callback(error.message);
                    }
                })
        } catch (ex) {
            // console.log('WriteContractError >>> ', ex);
            console.log(ex)
            if (ex.message) {
                console.log("rejected");
                // setTxStatus({ status: 'rejected', payload: { uuid, error: ex.message } });
                return callback(ex.message)
            }
            console.log("rejected");
            // setTxStatus({ status: 'rejected', payload: { uuid, error: ex } });
            callback(ex.message)
        }
    }

    return <Web3Context.Provider value={{callContractWait}}> {children}</Web3Context.Provider>
}