import { Route, Routes } from "react-router-dom";

import "./App.css";
import './styles/tailwindcss v3.4.1.css'

import TokenCreatePage from "./pages/TokenCreatePage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<TokenCreatePage />} />
      </Routes>
    </>
  );
}

export default App;
