import React from 'react';
import { useChainId, useConfig } from 'wagmi';
import { chainData } from '../chainData';

const CreatedTokenPanel = ({name, symbol, decimails, supply, tokenAddr, setCreatedTokenAddr}) => {
    return (
        <div className='fixed top-0 left-0 w-screen h-screen z-50'>
            <div className={`flex absolute top-0 left-0 w-screen h-screen flex flex-row items-center justify-center`}>
            {
                <div
                    className="flex mt-14"
                    style={{
                        borderRadius: '21.2px',
                        padding: '1.2px',
                        marginLeft: '2rem',
                        marginRight: '2rem',
                        maxWidth: '360px',
                        width: '100%',
                    }}
                >
                    <div
                        className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium transition-transform-background motion-reduce:transition-none z-10 bg-cyberpunk-card-bg rounded-[21.2px]"
                        tabIndex={-1}
                        style={{
                            width: "100%",
                            padding: "15px 10px 15px 10px"
                        }}
                    >
                        <div 
                            className="relative flex w-full p-3 flex-auto flex-col place-content-inherit align-items-inherit h-auto break-words text-left overflow-y-auto subpixel-antialiased px-3 lg:px-6"
                        >
                            <div className="flex flex-col gap-4">
                                <InputField label="Name" name={name} />
                                <InputField label="Symbol" name={symbol} />
                                <InputField label="Decimals" name={decimails} />
                                <InputField label="Supply" name={supply} />
                                <AddressItem address={tokenAddr} />
                                <button
                                    className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 rounded-medium [&>svg]:max-w-[theme(spacing.unit-8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover frosted-glass-teal"
                                    type="button"
                                    onClick={() => setCreatedTokenAddr('')}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

const InputField = ({label, name}) => {
    return (<div
        className="group flex flex-col w-full relative justify-end"
        data-slot="base"
        data-filled="true"
        data-filled-within="true"
        data-has-elements="true"
        data-has-label="true"
        data-has-value="true"
    >
        <div data-slot="main-wrapper" className="h-full flex flex-col">
            <h1 className='text-left'>{label} : </h1>
            <span className="font-semibold relative -top-1 text-center">{name}</span>
        </div>
    </div>);
};

const AddressItem = ({ address }) => {    
    const config = useConfig()
    const chainId = useChainId({ config })

    const copyAddr = async (acc) => {
        navigator.clipboard.writeText(acc).then(() => {
            // showToast(`Selected token address is copied : ${acc}`, 1000);
            console.log(`Selected token address is copied : ${acc}`);
        }).catch(err => {
            console.error('Failed to copy!', err);
        });
    };
    return (<div
        className="group flex flex-col w-full relative justify-end"
        data-slot="base"
        data-filled="true"
        data-filled-within="true"
        data-has-elements="true"
        data-has-label="true"
        data-has-value="true"
    >
        <div data-slot="main-wrapper" className="h-full flex flex-col">
            <h1 className='text-left'>Token Address: </h1>
            <span className="flex justify-between font-semibold w-full relative -top-1 text-center pl-1">
                <p className='flex underline'>
                    <a href={`${chainData[chainId].url}/token/${address}`} target="_blank">
                        {address.slice(0, 12) + " ... " + address.slice(32, 42)}
                    </a>
                </p>
                <button
                    className='flex'
                    style={{
                        right:'0px'
                    }}
                    onClick={() => copyAddr(address)}
                    >
                    <img
                        className="w-5 h-5"
                        src="./docs.svg"
                        alt="no image"
                    />
                </button>
            </span>
        </div>
    </div>);
}

export default CreatedTokenPanel;
