import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TokenCreatePanel from '../components/TokenCreatePanel';
import RevokeMintAuthorityPanel from '../components/RevokeMintAuthorityPanel';
import Collapsible from 'react-collapsible';

const inLocalTest = false;

const Section_1 = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-20'>
            <div className="flex flex-col gap-16">
                <div className="flex flex-col gap-5">
                    <h1 className="text-3xl font-bold">Rakesh Token Launcher</h1>
                    {/* <p className="text-base">
                        The perfect tool to create Oasis tokens.
                        <br />
                        Simple, user-friendly, and fast.
                    </p> */}
                    <p className="text-base">
                        Hi Sers.
                        <br />
                        Welcome to my token launcher. This is the perfect tool to quickly create your own memecoin on Oasis. As you know we sold all our cows for this. Please launch your own memecoin and support my village.
                    </p>
                    <TokenCreatePanel inLocalTest={inLocalTest}/>
                </div>
                {/* <RevokeMintAuthorityPanel /> */}
            </div>
            <div className='flex items-center'>
                <div className="flex flex-col gap-3">
                    <h1 className="text-2xl font-bold">
                        How to use Rakesh Token Launcher
                    </h1>
                    <p className="text-base">
                        1. Connect your Wallet.
                    </p>
                    <p className="text-base">
                        2. Specify the desired name for your Token
                    </p>
                    <p className="text-base">
                        3. Indicate the symbol (max 8 characters).
                    </p>
                    <p className="text-base">
                        4. Select the decimals quantity.
                    </p>
                    <p className="text-base">
                        5. Determine the Supply of your Token.
                    </p>
                    <p className="text-base">
                        6. Click on create, accept the transaction and wait until your tokens are ready.
                    </p>
                    {/* <p className="text-base">
                        The cost of Token creation is 200 Rose, covering all fees for Token Creation.
                    </p> */}
                    {/* <h2 className="text-2xl font-bold">
                        Revoke Freeze Authority: 
                    </h2>
                    <p>
                        If you want to create a liquidity pool you will need to &quot;Revoke Freeze
                        Authority&quot; of the Token, you can do that here. The cost is 0.1 SOL.
                    </p>
                    <h2 className="text-2xl font-bold">
                        Revoke Mint Authority:
                    </h2>
                    <p className="mb-10">
                        Revoking mint authority ensures that there can be no more tokens minted
                        than the total supply. This provides security and peace of mind to buyers. 
                        The cost is 0.1 SOL
                    </p> */}
                    <br />
                    <p className="text-base">
                        Once the creation process starts, it will only take a few seconds! Once complete, 
                        you will receive the total supply of the token in your wallet.
                    </p>
                    <p className="text-base">
                        With Rakesh Token Launcher, managing your tokens is straightforward and cost-effective. 
                        Simply connect your wallet to create new tokens, adjust their supply, or freeze them when necessary. 
                        Experience the seamless process of Oasis Token creation with us!
                    </p>
                    {/* <p className="text-base">
                        You can choose to revoke mint authority later if you choose
                    </p> */}
                </div>
            </div>
        </div>
    );
}

// interface FAQProps {
//     question: string;
//     answer: string;
// }
const FAQ = ({ question, answer }) => {
    return (
        <Collapsible
          trigger={
            <h2>
                <button
                    className="flex py-4 w-full h-full gap-3 items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 transition-opacity"
                    type="button"
                    id="faq-toggle"
                >
                    <div className="flex-1 flex flex-col text-start">
                        <span className="text-foreground text-large">{question}</span>
                    </div>
                    <span
                        aria-hidden="true"
                        className={`text-default-400 transition-transform ${
                            '-rotate-90'
                        } rtl:-rotate-180 rtl:data-[open=true]:-rotate-90`}
                    >
                        <svg
                            aria-hidden="true"
                            fill="none"
                            focusable="false"
                            height="1em"
                            role="presentation"
                            viewBox="0 0 24 24"
                            width="1em"
                        >
                            <path
                                d="M15.5 19l-7-7 7-7"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </span>
                </button>
            </h2>
          }
          triggerWhenOpen={
            <h2>
                <button
                    className="flex py-4 w-full h-full gap-3 items-center tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 transition-opacity"
                    type="button"
                    id="faq-toggle"
                >
                    <div className="flex-1 flex flex-col text-start">
                        <span className="text-foreground text-large">{question}</span>
                    </div>
                    <span
                        aria-hidden="true"
                        className="text-default-400 transition-transform rotate-0 data-[open=true]:-rotate-90 rtl:-rotate-180 rtl:data-[open=true]:-rotate-90"
                        data-open="true"
                    >
                        <svg
                            aria-hidden="true"
                            fill="none"
                            focusable="false"
                            height="1em"
                            role="presentation"
                            viewBox="0 0 24 24"
                            width="1em"
                        >
                            <path
                                d="M15.5 19l-7-7 7-7"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                            />
                        </svg>
                    </span>
                </button>
            </h2>
          }
        >
          {/* Content to be collapsed or expanded */}
            <section
                className="overflow-y-hidden will-change-auto opacity-100 h-auto py-2"
                role="region"
                aria-labelledby="faq-toggle"
            >
                <div>{answer}</div>
            </section>
        </Collapsible>
    )
};

const Section_2 = () => {    
    const faqs = [
        {
            id: 0,
            question: "What is the Rakesh Token Launcher?",
            answer: 'Our token launcher is an advanced Smart Contract empowering users to quickly create customized Token (On Oasis), all with the desired preferences in terms of supply, name, symbol and decimals.'
        },
        {
            id: 1,
            question: "Is it safe to create a memecoin through the Rakesh Launcher?",
            answer: 'Our tool is coded by very educated people in exchange of many cows. Our Launcher will be audited. So yes, it is safe.'
        },
        {
            id: 2,
            question: "How much time will it take before my memecoin is live?",
            answer: 'The time of your Token Creation depends on the TPS Status of Oasis, in general it takes a few seconds, ser.'
        },
        {
            id: 3,
            question: "How much does it cost?",
            answer: 'As my village is in need of food, we charge 200 Rose coins. Plus any applicable gas fees depending on the network. Very cheap.'
        },
        {
            id: 4,
            question: "Wich wallet can I use?",
            answer: 'Any preferred wallet like Metamask, no cows ser.'
        },
    ];
    
    return (
        <div>
            <h1 className="text-3xl font-bold mb-8">
                Frequently Asked Questions
            </h1>
            <div>
                <div className="w-full px-0" data-orientation="vertical">
                    {faqs.map((faq, id) => (
                        <div key={faq.id}>
                            {id != 0 && <hr className="shrink-0 bg-divider border-none w-full h-divider" role="separator" />}
                            <FAQ question={faq.question} answer={faq.answer} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const Section_3 = () => {
    return (
        <div>
            <div className="flex flex-col gap-5 mb-10">
                <h1 className="text-3xl font-bold">
                    Why launch Oasis tokens using Rakesh token launcher
                </h1>
                <p className="text-base">
                    Two reasons my village made this tool, first and foremost we need food. 
                    Secondly the Oasis blockchain is in urgent need of expanding. 
                    Memecoins are more popular than ever, we believe by providing a simple and easy to use tool. 
                    We open the meme floodgates to Oasis, boosting Oasis in its whole.
                </p>
                <p className="text-base">
                    In short, even a toddler could launch its own meme through our Token Launcher, 
                    its straight forward and done in a couple of minutes.
                </p>
            </div>
        </div>
    );
}

function TokenCreatePage() {
    return (
        <div className='min-h-screen h-auto dark bg-gradient-to-b to-[#110d36] from-[#0c0927] text-foreground'>
            <Header inLocalTest={inLocalTest} />
            <div className="pb-10 sm:pb-20 mt-10 px-8 xl:px-5 max-w-screen-lg mx-auto grid gap-24">
                <Section_1 />
                <Section_2 />
                <Section_3 />
            </div>
            <Footer />
        </div>
    );
}

export default TokenCreatePage;
