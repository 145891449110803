import axios from 'axios';

import metadata from './contract/metadata.json'
import { tokenContractString } from './contract/TokenLauncherContract'


const axiosInstance = axios.create({ baseURL: 'https://sourcify.dev/server' });

// const axios = require('axios')
// const fs = require('fs')

// const tokenContractString = fs.readFileSync('./src/utils/contract/TokenLauncher.sol').toString();
// const metadata = fs.readFileSync('./src/utils/contract/metadata.json');

export const verifyTokenContract = async (tokenAddr, creatorTxHash) => {  
    
    // console.log('tokenAddr : ', tokenAddr)
    // console.log('creatorTxHash : ', creatorTxHash)

    const verificationData = {
        "address": tokenAddr,
        "chain": "23294",
        "files": {
            "metadata.json": JSON.stringify(metadata),
            "Token.sol": tokenContractString
        },
        "creatorTxHash": creatorTxHash,
        "chosenContract": "5"
    };
  
    try {
        const response = await axiosInstance.post('/verify', verificationData); // Ensure this is the correct API endpoint for contract verification
        
        if (response.data.status === "1") {
            console.log("Contract verified successfully!");
        } else {
            console.log("Verification failed:", response.data.result);
        }
    } catch (error) {
        console.error("Error during verification:", error);
    }
};

// verifyTokenContract('0xf62E2F053DC74B9919653a1Ddf31fB0eF7AefB64', '0xa395db4bf74e61c51c4aa41113ac40869dec09e8348c2b31991a49b87cb93af7');