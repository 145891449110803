import { ConnectButton } from '@rainbow-me/rainbowkit';
import React from 'react';

// RoundedButton Component
const RoundedButton = ({
    className = '',
    onClick,
    disabled = false,
    children,
    ...other
}) => {
    return (<button
            disabled={disabled}
            onClick={onClick}
            className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 rounded-medium [&>svg]:max-w-[theme(spacing.unit-8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover frosted-glass-teal"
            type="button"
            {...other}
        >
            {children}
        </button>
    );
};
  
// ConnectWallet Component
const ConnectWallet = () => {
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                return (
                    <div className="h-full"
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    <RoundedButton
                                        // sm='true'
                                        onClick={openConnectModal}
                                        // className={"bg-gradient-blue-to-purple border-none outline-none"}
                                    >
                                        Connect
                                    </RoundedButton>
                                );
                            }
                            if (chain.unsupported) {
                                return (
                                    <RoundedButton
                                        // sm='true'
                                        onClick={openChainModal}
                                        // className={"bg-gradient-blue-to-purple border-none outline-none"}
                                    >
                                        Wrong
                                    </RoundedButton>
                                );
                            }
                            return (
                                <div className="h-full flex gap-1.5">
                                    <RoundedButton
                                        // className={"bg-gradient-blue-to-purple !py-0 flex-col border-none hover:outline-0 hover:brightness-125 px-2"}
                                        // sm='true'
                                        // style={{width: "100px"}}
                                        onClick={openAccountModal}
                                    >
                                        {account.displayName}
                                        <span className='IsAndroid'>
                                            {account.displayBalance
                                                ? ` (${account.displayBalance})`
                                                : ''}</span>
                                    </RoundedButton>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};


const Header = ({inLocalTest = false}) => {
    return (
        <nav
            className="flex z-40 w-full h-auto items-center justify-center data-[menu-open=true]:border-none sticky top-0 inset-x-0 backdrop-blur-lg data-[menu-open=true]:backdrop-blur-xl backdrop-saturate-150 bg-[#131a35]"
            style={{ '--navbar-height': '4rem' }}
        >
            <header className="z-40 flex lg:px-6 px-3 gap-4 w-full flex-row relative flex-nowrap items-center justify-between h-[var(--navbar-height)] max-w-full">
                <div className="flex basis-0 lg:gap-5 gap-1 flex-row flex-grow flex-nowrap justify-start bg-transparent items-center no-underline text-medium whitespace-nowrap box-border">
                    <img
                        alt="Logo"
                        loading="lazy"
                        width="2049"
                        height="531"
                        decoding="async"
                        data-nimg="1"
                        className="h-10 w-auto"
                        style={{ color: 'transparent', borderRadius: "100%" }}
                        srcSet="./logo.jpg 1x"
                        src="./logo.jpg"
                    />
                    <h1 className="text-2xl font-bold">
                        Rakesh
                    </h1>
                </div>
                <ul
                    className="flex gap-4 h-full flex-row flex-nowrap items-center data-[justify=start]:justify-start data-[justify=start]:flex-grow data-[justify=start]:basis-0 data-[justify=center]:justify-center data-[justify=end]:justify-end data-[justify=end]:flex-grow data-[justify=end]:basis-0"
                    data-justify="end"
                >
                    <li className="text-medium whitespace-nowrap box-border list-none data-[active=true]:font-semibold">
                    { inLocalTest? <RoundedButton>In Local Testing</RoundedButton> : <ConnectWallet /> }
                    </li>
                </ul>
            </header>
        </nav>
    );
};

export default Header;
