
export const chainData = {
    23295 : {
        addr: '0x4aa7705628f57328C42146f74111c14A49870296',
        url: 'https://explorer.oasis.io/testnet/sapphire',
    },
    23294 : {
        addr: '0x620Fdee06f9b28bC4ecaf5451A2b9f5fa12A180A',
        url: 'https://explorer.oasis.io/mainnet/sapphire',
    }
}
