import React, { useEffect, useState } from 'react';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import { parseUnits, ethers } from 'ethers';
import { useChainId, useConfig, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

import { useEthersSigner } from "../utils/provider";
import tokenLauncherContract from '../utils/contract/TokenLauncher.json'

import CreatedTokenPanel from './CreatedTokenPanel';
import { chainData } from '../chainData';
import { verifyTokenContract } from '../utils/conract_verify';

const TokenCreatePanel = ({ inLocalTest = false }) => {
    const config = useConfig()
    const chainId = useChainId({ config })
    const signer = useEthersSigner(chainId);

    const [name, setName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [decimals, setDecimals] = useState('');
    const [supply, setSupply] = useState('');

    const [enableCreate, setEnableCreate] = useState(false);

    const [createdTokenAddr, setCreatedTokenAddr] = useState('');
    const [createdTxnHash, setCreatedTxnHash] = useState('');

    useEffect(() => {
        if (name.trim() === ''
            || symbol.trim() === ''
            || decimals.trim() === ''
            || supply.trim() === ''
        ) {
            setEnableCreate(false);
        } else {
            setEnableCreate(true);
        }
    }, [name, symbol, decimals, supply])

    /// mint tx and form handling
    const { data: tokenCreateHash } = useWriteContract();
    const { data: createReceipt } = useWaitForTransactionReceipt({
        hash: tokenCreateHash,
        confirmations: 2,
        query: {
            enabled: !!tokenCreateHash && chainId === 23295,
        },
        chainId: chainId,
    });

    useEffect(() => {
        if (createReceipt && createReceipt.logs[0]) {
            setCreatedTokenAddr(createReceipt.logs[0].address);
        }
    }, [createReceipt])

    useEffect(() => {
        if (createdTxnHash != '' && createdTokenAddr != '')
            verifyTokenContract(createdTokenAddr, createdTxnHash);
    }, [createdTokenAddr, createdTxnHash])

    const createOasisToken = async () => {
        if (!enableCreate) return;
        if (inLocalTest) {
            setCreatedTokenAddr('inLocalTest');
            return;
        }

        // console.log('config: ', config)

        setEnableCreate(false);

        let unpackedSig = null;
        const time = parseInt(new Date().getTime() / 1000)
        const signature = await signer.signTypedData({
            name: "TokenLauncher.SignIn",
            version: "1",
            chainId: chainId,
            verifyingContract: chainData[chainId].addr,
        }, {
            SignIn: [
                { name: "user", type: "address" },
                { name: "time", type: "uint32" },
            ],
        }, {
            user: signer.address,
            time: time,
        });
        unpackedSig = ethers.Signature.from(signature);

        try {
            const contract = new ethers.Contract (chainData[chainId].addr, tokenLauncherContract.abi, signer)
            const txn = await contract.createToken(
                {
                    user: signer.address,
                    time,
                    rsv: unpackedSig
                },                    
                name,
                symbol,
                supply ? parseInt(decimals) : 0,
                supply ? parseInt(supply) : 0,
                {value: parseUnits('200', 18) }
            )
            const txnReceipt = await txn.wait();

            if (txnReceipt && txnReceipt.logs[0]) {
                const tokenAddr = txnReceipt.logs[0].address;
                const tokenCreatedHash = txnReceipt.logs[0].transactionHash;

                console.log("tokenAddr : ", tokenAddr)

                setCreatedTxnHash(tokenCreatedHash);
                setCreatedTokenAddr(tokenAddr);        
            }
        } catch (e) {
            console.log("Faild to create Oasis token :", e)
        }
        setEnableCreate(true);
    }

    return (    
        <div
            className="relative"
            style={{
                borderRadius: '21.2px',
                padding: '1.2px',
                backgroundColor: 'white'
            }}
        >
            {createdTokenAddr &&
                <CreatedTokenPanel
                    name={name}
                    symbol={symbol}
                    decimails={decimals}
                    supply={supply}
                    tokenAddr={createdTokenAddr}
                    setCreatedTokenAddr={setCreatedTokenAddr}
                />}
            <div
                className="flex flex-col relative overflow-hidden h-auto text-foreground box-border outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 shadow-medium transition-transform-background motion-reduce:transition-none z-10 bg-cyberpunk-card-bg rounded-[21.2px]"
                tabIndex={-1}
            >
                <div
                    className="relative flex w-full p-3 flex-auto flex-col place-content-inherit align-items-inherit h-auto break-words text-left overflow-y-auto subpixel-antialiased py-5 px-3 lg:py-8 lg:px-6"
                >
                    <form noValidate>
                        <div className="flex flex-col gap-4">
                            <InputField label="Name" req_text="Name is required" type="text" inputValue={name} setInputValue={setName} />
                            <InputField label="Symbol" req_text="Symbol is required" type="text" inputValue={symbol} setInputValue={setSymbol} />
                            <InputField label="Decimals" req_text="Decimals is required" type="number" min={0} max={18} inputValue={decimals} setInputValue={setDecimals} />
                            <InputField label="Supply" req_text="Supply is required" type="number" min={1} inputValue={supply} setInputValue={setSupply} />
                            {
                                inLocalTest ?
                                    <RoundedButton
                                        onClick={createOasisToken}
                                        className="bg-black rounded-md flex flex-row items-center px-2"
                                    >
                                        Token Create
                                    </RoundedButton>
                                    :
                                    <ConnectWallet createToken={createOasisToken} />
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const InputField = ({
    label,
    req_text,
    type,
    min,
    max,
    inputValue,
    setInputValue
}) => {
    const [required, setRequired] = useState(false);

    // Handle input change
    const handleChange = (event) => {
        setRequired(false);
        handleBlur();

        setInputValue(event.target.value); // Update state with new input value
    };

    const handleBlur = () => {
        if (inputValue.trim() === '') {
            setRequired(true); // Set required if input is empty
        } else {
            setRequired(false);
        }
    }

    return (<div
        className="group flex flex-col w-full relative justify-end data-[has-label=true]:mt-[calc(theme(fontSize.small)_+_10px)]"
        data-slot="base"
        data-filled="true"
        data-filled-within="true"
        data-has-elements="true"
        data-has-label="true"
        data-has-value="true"
    >
        <div data-slot="main-wrapper" className="h-full flex flex-col">
            <div
                data-slot="input-wrapper"
                className="relative w-full inline-flex tap-highlight-transparent flex-row items-center shadow-sm px-3 gap-3 border-1 data-[hover=true]:bg-default-200 group-data-[focus=true]:bg-default-100 h-unit-10 min-h-unit-10 rounded-medium transition-background motion-reduce:transition-none !duration-150 outline-none group-data-[focus-visible=true]:z-10 group-data-[focus-visible=true]:ring-2 group-data-[focus-visible=true]:ring-focus group-data-[focus-visible=true]:ring-offset-2 group-data-[focus-visible=true]:ring-offset-background"
                style={{ cursor: 'text' }}
            >
                <label
                    data-slot="label"
                    className="absolute pointer-events-none origin-top-left subpixel-antialiased block text-foreground-500 will-change-auto !duration-200 !ease-out motion-reduce:transition-none transition-[transform,color,left,opacity] group-data-[filled-within=true]:text-foreground group-data-[filled-within=true]:pointer-events-auto pb-0 z-20 top-1/2 -translate-y-1/2 group-data-[filled-within=true]:left-0 left-3 text-small group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.small)/2_+_20px)] pe-2 max-w-full text-ellipsis overflow-hidden font-semibold"
                    id="react-aria-:R59imulsldaH1:"
                    htmlFor="react-aria-:R59imulslda:"
                >
                    {label}
                </label>
                <div
                    data-slot="inner-wrapper"
                    className="inline-flex w-full items-center h-full box-border"
                >
                    <input
                        data-slot="input"
                        className="w-full font-normal bg-transparent !outline-none placeholder:text-foreground-500 focus-visible:outline-none data-[has-start-content=true]:ps-1.5 data-[has-end-content=true]:pe-1.5 text-small group-data-[has-value=true]:text-default-foreground h-full"
                        aria-label="Decimals"
                        type={type}
                        placeholder=" "
                        id="react-aria-:R59imulslda:"
                        aria-labelledby="react-aria-:R59imulslda: react-aria-:R59imulsldaH1:"
                        aria-describedby="react-aria-:R59imulsldaH3: react-aria-:R59imulsldaH4:"
                        min="0"
                        max="9"
                        name="decimal"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        onClick={handleBlur}
                        onKeyDown={handleBlur}
                        value={inputValue}
                    />
                </div>
            </div>
            {required && <div
                data-slot="helper-wrapper"
                className="group-data-[has-helper=true]:flex p-1 relative flex-col gap-1.5"
            >
                <div
                    data-slot="error-message"
                    className="text-tiny text-danger"
                    id="react-aria-:R99imulsldaH4:"
                >
                    {req_text}
                </div>
            </div>}
        </div>
    </div>);
};

// RoundedButton Component
const RoundedButton = ({
    className = '',
    onClick,
    disabled = false,
    children,
    ...other
}) => {
    return (<button
        disabled={disabled}
        onClick={onClick}
        className={`z-0 w-full group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 rounded-medium [&>svg]:max-w-[theme(spacing.unit-8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-default text-default-foreground data-[hover=true]:opacity-hover frosted-glass-teal ${className}`}
        {...other}
        type="button"
    >
        {children}
    </button>
    );
};

const ConnectWallet = ({ createToken }) => {
    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus || authenticationStatus === 'authenticated');

                return (
                    <div
                        className="h-full"
                        {...(!ready && {
                            'aria-hidden': true,
                            style: {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {!connected ? (
                            <RoundedButton
                                onClick={openConnectModal}
                            >
                                Select Wallet
                            </RoundedButton>
                        ) : chain.unsupported ? (
                            <RoundedButton
                                onClick={openChainModal}
                                className="bg-gradient-blue-to-purple border-none outline-none"
                            >
                                Wrong Network
                            </RoundedButton>
                        ) : (
                            <div className="h-full flex gap-1.5">
                                <RoundedButton
                                    onClick={createToken}
                                    className="bg-black rounded-md flex flex-row items-center px-2"
                                >
                                    Token Create
                                </RoundedButton>
                            </div>
                        )}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};

export default TokenCreatePanel;
